<template>
  <div>
    <table-component
      :model="model"
    />
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import ModelAgentSubscriptionPlanCoupon from '@/dc-it/models/ModelAgentSubscriptionPlanCoupon'

export default {
  name: 'AgentModelSubscriptionCouponsView',
  components: { TableComponent },
  data() {
    return {
      model: ModelAgentSubscriptionPlanCoupon,
    }
  },
}
</script>

<style scoped>

</style>
