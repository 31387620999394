import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Select from '@/dc-it/types/Select'
import helpers from '@/dc-it/services/helpers'

export default class ModelAgentSubscriptionPlanCoupon extends Model {
    endpoint = 'model_agent_subscription_coupons';

    // 1=>Fixed 2=>Percent
    discountTypes = {
      1: 'Fijo',
      2: 'Porcentaje',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('code', 'Código de aplicación'),
      new Field('start_date', 'Fecha de inicio', 'date'),
      new Field('end_date', 'Fecha de fin', 'date'),
      new Field('use_limit', 'Límite de usos', 'number'),
      new Field('uses_count', 'Usos realizados', 'number').disabled().hide(),
      new Select('discount_type', 'Tipo de descuento', this.discountTypes, '1'),
      new Field('discount_amount', 'Valor de descuento', 'number').applyMask(value => {
        if (this.getAttr('discount_type').toString() === '1') {
          return `$${helpers.numberFormat(value)}`
        }
        return `${value}%`
      }),

    ];

    clone = () => ModelAgentSubscriptionPlanCoupon;
}
